import {useTranslation} from 'react-i18next';
import {Box, Container, Grid} from '@mui/material';
import {Skeleton} from '@mui/lab';
import PageHeadline from '../../components/PageHeadline';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {formatDateTime} from '../../utils/DateUtils';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {EventItem, TicketConfigListItem} from '../../model/Event';
import {useParams} from 'react-router';
import useApiRequest from '../../hooks/useApiRequest';

function OrderTicketListPage() {
  const {t} = useTranslation();
  const {useAxiosOutletOrderAPI} = useAxiosContext();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TICKETS);
  const {id} = useParams();

  const {
    entityData: entityListData,
    isEntityLoading: isEntityListLoading,
    entityError: hasEntityListError,
  } = useApiRequest<ResponseListWrapper<TicketConfigListItem>>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlets/${id}/outlet-items`, params: {formatted: true}},
    pageName: 'TicketListPage',
    options: {manual: false, useCache: false},
  });

  const {entityData} = useApiRequest<EventItem>({
    useAxios: useAxiosOutletOrderAPI,
    config: {
      url: `/outlets/${id}`,
      params: {
        formatted: true,
        type: 'event',
      },
    },
    options: {manual: false},
    ignoreLoader: true,
  });

  const tableColumns = useTableColumns({
    featureName: FeatureName.TICKETS,
    defaultActionColumn: true,
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>
                  {t('order-tickets.headline')}: {entityData?.name}
                </PageHeadline>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid
            item
            xs={12}
            sx={{height: 'calc(100vh - 64px - 85px)'}}
            {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
          >
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              entityListData?.results && (
                <DataGridCustom
                  tableColumns={tableColumns}
                  rows={entityListData.results.map((entity: any, index: number) => {
                    return {
                      ...entity,
                      created_at: entity.created_at && formatDateTime(entity.created_at),
                      last_updated_at: entity.last_updated_at && formatDateTime(entity.last_updated_at),
                      actions: entity.outlet_item_id,
                      id: index,
                    };
                  })}
                  userPermissions={userPermissions}
                />
              )
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OrderTicketListPage;
