import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {useAxiosContext} from '../../../context/AxiosContext';
import 'react-h5-audio-player/lib/styles.css';
import {EventOrder} from '../../../model/Event';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import {AxiosPromise} from 'axios';
import useApiRequest from '../../../hooks/useApiRequest';

interface OrderDetailDialogProps {
  orderId: string;
  onClose: () => void;
  isOpen: boolean;
  getEntityListData: () => AxiosPromise<ResponseListWrapper<EventOrder>>;
}

export default function OrderDetailDialog({orderId, onClose, isOpen, getEntityListData}: OrderDetailDialogProps) {
  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const [orderStatus, setOrderStatus] = useState<string>('');
  const [statusDetail, setStatusDetail] = useState<string>();

  const {
    entityData: orderDetail,
    isEntityLoading: isOrderDetailLoading,
    reFetch: getOrderDetail,
  } = useApiRequest<EventOrder>({
    useAxios: useAxiosEventAPI,
    config: {url: `outlet-orders/${orderId}`},
    options: {manual: false},
    pageName: 'EventPage',
  });

  const {isEntityLoading: isPatchOrderDetailLoading, reFetch: patchOrderDetail} = useApiRequest<EventOrder>({
    useAxios: useAxiosEventAPI,
    config: {method: 'PATCH'},
    options: {manual: true},
    pageName: 'EventPage',
    fetchEntityList: getEntityListData,
  });
  useEffect(() => {
    if (orderDetail) {
      setOrderStatus(orderDetail.status);
    }
  }, [orderDetail]);

  const {t} = useTranslation();
  function buildDetailsToDisplay(order: EventOrder) {
    return {
      'outlet-name': order.outlet.name,
      name: `${order.lead.first_name} ${order.lead.last_name}`,
      email: order.lead.email,
      'primary-phone': order.lead.primary_phone,
    };
  }

  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth={'md'}>
      <DialogTitle>
        <Typography variant="h4">{t('event-order.dialog.overview')}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{padding: 0}}>
        {isOrderDetailLoading && <Skeleton variant="rectangular" height={500} />}
        <Grid container sx={{p: 1}}>
          {orderDetail &&
            !isOrderDetailLoading &&
            Object.entries(buildDetailsToDisplay(orderDetail)).map(([column, label], index) => {
              console.log(column);
              return (
                <>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className={index % 2 ? 'overview-dialog-row-odd' : 'overview-dialog-row-even'}
                    sx={{
                      paddingInline: '25px',
                      paddingBlock: '14px',
                    }}
                  >
                    <Typography component="span" sx={{fontWeight: '500'}}>
                      {t(`event-order.dialog.${column}`)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{paddingInline: '25px', paddingBlock: '14px'}}
                    className={index % 2 ? 'overview-dialog-row-odd-light' : 'overview-dialog-row-even-light'}
                  >
                    <Typography sx={{whiteSpace: 'pre-wrap'}}>{column !== 'status' && label}</Typography>
                  </Grid>
                </>
              );
            })}
        </Grid>
        <hr color={'#efefef'} hidden={isOrderDetailLoading} />
        <Box sx={{pl: 1, py: 3}} hidden={isOrderDetailLoading}>
          <Typography variant={'h3'} sx={{m: 0, pl: 1}}>
            {t('event-order.dialog.order-number')}: {orderDetail?.outlet_order_id}
          </Typography>
          {isPatchOrderDetailLoading ? (
            <Box height={40} sx={{mt: 4}}>
              <CircularProgress size={30} />
            </Box>
          ) : (
            <>
              <FormControl sx={{minWidth: '130px', height: '40px', my: 4}}>
                <InputLabel id="status-label">{t('event-order.dialog.status')}</InputLabel>
                <Select
                  id="status"
                  name="status"
                  value={orderStatus}
                  label={t('event-order.dialog.status')}
                  onChange={(e) => {
                    setOrderStatus(e.target.value);
                    setStatusDetail('');
                  }}
                  variant={'outlined'}
                >
                  <MenuItem value={'cancelled'}>{t('events.status.cancelled')}</MenuItem>
                  <MenuItem value={'refunded'}>{t('events.status.refunded')}</MenuItem>
                  <MenuItem value={'pending'}>{t('events.status.pending')}</MenuItem>
                  <MenuItem value={'verified'}>{t('events.status.verified')}</MenuItem>
                  <MenuItem value={'failed'}>{t('events.status.failed')}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth={true}
                sx={{pr: 1}}
                label={t('event-order.dialog.status-detail')}
                multiline
                maxRows={4}
                InputLabelProps={{shrink: true}}
                value={statusDetail}
                onChange={(e) => setStatusDetail(e.target.value)}
              />
            </>
          )}
        </Box>
        <hr color={'#efefef'} hidden={isOrderDetailLoading} />
        <Box sx={{pl: 1, py: 3}} hidden={isOrderDetailLoading}>
          <Typography variant={'h3'} sx={{mb: 4, pl: 1}}>
            {t(`event-order.dialog.order-details-headline`)}
          </Typography>
          <Grid container spacing={2} sx={{width: '400px', pl: 2}}>
            <Grid
              item
              xs={4}
              className={'overview-dialog-row-even'}
              sx={{
                paddingInline: '25px',
                paddingBlock: '14px',
              }}
            >
              <Typography>{t(`event-order.dialog.ticket-name`)}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={'overview-dialog-row-even'}
              sx={{
                paddingInline: '25px',
                paddingBlock: '14px',
              }}
            >
              <Typography>{t(`event-order.dialog.quantity`)}</Typography>
            </Grid>
            <Grid
              item
              xs={4}
              className={'overview-dialog-row-even'}
              sx={{
                paddingInline: '25px',
                paddingBlock: '14px',
              }}
            >
              <Typography>{t(`event-order.dialog.ticket-price`)}</Typography>
            </Grid>
            {orderDetail?.outlet_order_details?.map((outletOrderDetail) => (
              <>
                <Grid item xs={4} sx={{py: 2}}>
                  <Typography>{outletOrderDetail.outlet_item_config.name}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{outletOrderDetail.quantity}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>{outletOrderDetail.outlet_item_config.price}</Typography>
                </Grid>
              </>
            ))}
            <Grid item xs={4} borderTop={'1px solid #efefef'} sx={{py: 2}}>
              <Typography>{t(`event-order.dialog.total`)}</Typography>
            </Grid>
            <Grid item xs={4} borderTop={'1px solid #efefef'}>
              <Typography>{orderDetail?.total_items}</Typography>
            </Grid>
            <Grid item xs={4} borderTop={'1px solid #efefef'}>
              <Typography>{orderDetail?.total_price}</Typography>
            </Grid>
          </Grid>
        </Box>
        <hr color={'#efefef'} hidden={isOrderDetailLoading} />
        <Box hidden={isOrderDetailLoading} sx={{pl: 1}}>
          <Typography variant={'h3'} sx={{pl: 1, pt: 3}}>
            {t(`event-order.dialog.attachments-headline`)}
          </Typography>
          {orderDetail?.attachments && !isOrderDetailLoading && (
            <ImageList sx={{width: 500, padding: '25px'}} cols={3} rowHeight={164}>
              {orderDetail.attachments.map((attachment) => (
                <a href={attachment} target="_blank">
                  <ImageListItem key={attachment}>
                    <img srcSet={attachment} src={attachment} alt={'attachment'} loading="lazy" />
                  </ImageListItem>
                </a>
              ))}
            </ImageList>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            patchOrderDetail({
              url: `outlet-orders/${orderId}`,
              data: {
                status: orderStatus,
                status_detail: statusDetail || null,
              },
            }).then(() => {
              getOrderDetail();
            });
          }}
        >
          {t('event-order.dialog.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
