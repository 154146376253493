import {useState} from 'react';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useAxiosContext} from '../../../context/AxiosContext';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from '@mui/material';
import {PageTopActions} from '../../PageTopActions';
import AccessControl, {UserPermissions} from '../../../components/shared/AccessControl';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import Button from '@mui/material/Button';
import {TestAttributes} from '../../../TestAttributes';
import TextField from '@mui/material/TextField';
import useApiRequest from '../../../hooks/useApiRequest';
import {TicketConfig} from '../../../model/Event';
import {AxiosPromise} from 'axios';
interface TicketConfigPageActionsProps {
  userPermissions: Array<UserPermissions> | null;
  handleOnClear: () => void;
  handleOnDeleteEntity: () => void;
  handleOnSave: () => void;
  getEntityData: Function;
}

export default function TicketConfigPageActions({
  userPermissions,
  handleOnClear,
  handleOnDeleteEntity,
  handleOnSave,
  getEntityData,
}: TicketConfigPageActionsProps) {
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const [dialogMode, setDialogMode] = useState<string | null>(null);
  const [withdrawalAmount, setWithdrawAmount] = useState<number>(0);
  const {id} = useParams();
  const {t} = useTranslation();
  const {useAxiosOutletOrderAPI} = useAxiosContext();
  const {reFetch: getEntityList} = useApiRequest<TicketConfig>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlet-item-configs`, params: {formatted: true}},
    options: {manual: true},
    ignoreLoader: true,
  });
  const {reFetch: postAdjustAvailableUnits} = useApiRequest<TicketConfig>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlet-item-configs/${id}/adjust-available-units`, method: 'post'},
    options: {manual: true},
    pageName: 'TicketsConfig',
    fetchEntityList: getEntityList,
  });
  function handleDialogAction(dialogMode: string) {
    postAdjustAvailableUnits({
      data: {amount: dialogMode === 'increase' ? withdrawalAmount : withdrawalAmount * -1},
    }).then(() => getEntityData());
  }
  return (
    <Grid item xs={12} md={6}>
      <PageTopActions>
        {id && (
          <>
            <Button
              {...{[TestAttributes.BUTTON_NAME]: 'increase-dialog-btn'}}
              id={'increase-dialog-btn'}
              color="secondary"
              onClick={() => setDialogMode('increase')}
            >
              {t('tickets-config.increase')}
            </Button>
            <Button
              {...{[TestAttributes.BUTTON_NAME]: 'decrease-dialog-btn'}}
              id={'increase-dialog-btn'}
              color="secondary"
              onClick={() => setDialogMode('decrease')}
            >
              {t('tickets-config.decrease')}
            </Button>

            <AccessControl
              userPermissions={userPermissions}
              allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
            >
              <ConfirmationDialog
                message={t('tickets-config.deactivate-message')}
                headline={t('tickets-config.deactivate')}
                isDialogOpen={isDeactivatedModalOpen}
                handleClose={() => setIsDeactivatedModalOpen(false)}
              >
                <Button
                  onClick={() => {
                    handleOnDeleteEntity();
                    setIsDeactivatedModalOpen(false);
                  }}
                  {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                  id="confirmation-dialog-action-btn"
                >
                  {t('tickets-config.deactivate')}
                </Button>
              </ConfirmationDialog>

              <Button
                {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                id={'confirmation-dialog-btn'}
                color="secondary"
                onClick={() => setIsDeactivatedModalOpen(true)}
              >
                {t('tickets-config.deactivate')}
              </Button>
            </AccessControl>
          </>
        )}

        <Button
          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
          id={'confirmation-dialog-btn'}
          color="secondary"
          onClick={handleOnClear}
        >
          {t('shared.clear')}
        </Button>

        <AccessControl
          userPermissions={userPermissions}
          allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
        >
          <Button {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}} id={'save-btn'} onClick={handleOnSave}>
            {id ? t('shared.update') : t('shared.save')}
          </Button>
        </AccessControl>
        <Dialog
          open={Boolean(dialogMode)}
          onClose={() => {
            setDialogMode(null);
            setWithdrawAmount(0);
          }}
          fullWidth
        >
          <DialogTitle>
            <Typography fontSize={'1.5rem'} fontWeight={400}>
              {dialogMode === 'increase'
                ? t('tickets-config.increase-dialog-title')
                : t('tickets-config.decrease-dialog-title')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {dialogMode === 'increase' ? t('tickets-config.increase-dialog') : t('tickets-config.decrease-dialog')}
            </Typography>
            <TextField
              sx={{marginTop: '30px'}}
              value={withdrawalAmount}
              onChange={(e) => {
                const newValue = Number(e.target.value);
                if (newValue >= 0) {
                  setWithdrawAmount(newValue);
                }
              }}
              type={'number'}
              label={t('tickets-config.amount')}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={withdrawalAmount < 1} onClick={() => dialogMode && handleDialogAction(dialogMode)}>
              {t('tickets-config.submit')}
            </Button>
          </DialogActions>
        </Dialog>
      </PageTopActions>
    </Grid>
  );
}
