import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {OnSubmitHandler} from '../../form/model';
import {FormState} from '../../form/state/FormState';
import {DefaultResponse} from '../../services/model/DefaultResponse';
import {TestAttributes} from '../../TestAttributes';
import {difference} from '../../form/utils';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../context/AxiosContext';
import {EventItem, EventListItem} from '../../model/Event';
import {useNavigate} from 'react-router-dom';
import useApiRequest from '../../hooks/useApiRequest';

function EventPage() {
  const {id} = useParams();
  const {t} = useTranslation();

  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.EVENTS);
  const [event, setEvent] = useState<EventItem | null>(null);
  const formAPI = useFormAPI();

  const navigate = useNavigate();

  const {reFetch: getEntityList} = useApiRequest<ResponseListWrapper<EventListItem>>({
    useAxios: useAxiosEventAPI,
    config: {
      url: `/outlets`,
      params: {
        formatted: true,
        type: 'event',
      },
    },
    pageName: 'EventListPage',
    options: {manual: true},
  });

  const {
    entityData,
    isEntityLoading: isGetEntityLoading,
    reFetch: getEntityData,
  } = useApiRequest<EventItem>({
    useAxios: useAxiosEventAPI,
    config: {
      url: `/outlets/${id}`,
      params: {
        formatted: true,
        type: 'event',
      },
    },
    pageName: 'EventPage',
    options: {manual: true},
  });

  const {reFetch: patchEntityData} = useApiRequest<EventItem>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlets/${id}`, method: 'PATCH', params: {formatted: true}},
    options: {manual: true},
    pageName: 'EventPage',
    fetchEntityList: getEntityList,
  });

  const {reFetch: postEntityData} = useApiRequest<EventItem>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlets`, method: 'POST'},
    options: {manual: true},
    pageName: 'EventPage',
    fetchEntityList: getEntityList,
    redirectBaseUrlAfterSuccess: `/${FeatureName.EVENTS}`,
  });

  const {reFetch: deleteEntity} = useApiRequest<DefaultResponse>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlets/${id}`, method: 'delete'},
    options: {manual: true},
    pageName: 'EventPage',
    fetchEntityList: getEntityList,
    redirectBaseUrlAfterSuccess: `/${FeatureName.EVENTS}`,
  });

  const {entityData: ticketsStreamData, reFetch: getTicketStreamData} = useApiRequest<Blob>({
    useAxios: useAxiosEventAPI,
    config: {
      url: `/outlets/${id}/items-pdf`,
      responseType: 'blob',
      params: {
        formatted: true,
        type: 'event',
      },
    },
    pageName: 'EventListPage',
    options: {manual: true},
  });

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<EventItem>, state: FormState) => {
      if (id) {
        formData = difference(formData, event);
        patchEntityData({
          data: formData,
        }).then(() => {
          getEntityData();
        });
      } else {
        postEntityData({
          data: formData,
        }).then(() => {});
      }
    },
    [event]
  );

  useEffect(() => {
    if (id) {
      getEntityData();
    } else {
      setEvent(null);
    }
  }, [id]);

  useEffect(() => {
    if (entityData) {
      setEvent(entityData);
    }
  }, [entityData]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('events.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions sx={{rowGap: 1}}>
                  {id && (
                    <>
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DOWNLOAD_EVENT_TICKET_PDF]}
                      >
                        <Button
                          color="secondary"
                          id={'add-btn'}
                          onClick={() =>
                            getTicketStreamData().then((blob) => {
                              const pdfBlob = new Blob([blob.data], {type: 'application/pdf'});
                              const tempLink = document.createElement('a');
                              const url = window.URL.createObjectURL(pdfBlob);
                              tempLink.href = url;
                              tempLink.setAttribute('download', `${id}_${Date.now()}`);
                              document.body.appendChild(tempLink);
                              tempLink.click();
                              document.body.removeChild(tempLink);
                              window.URL.revokeObjectURL(url);
                            })
                          }
                          {...{[TestAttributes.BUTTON_NAME]: 'download-tickets-btn'}}
                        >
                          {t('events.download-tickets')}
                        </Button>
                      </AccessControl>
                      <AccessControl
                        userPermissions={userPermissions}
                        allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                      >
                        <ConfirmationDialog
                          message={t('events.deactivate-message')}
                          headline={t('events.deactivate')}
                          isDialogOpen={isDeactivatedModalOpen}
                          handleClose={() => setIsDeactivatedModalOpen(false)}
                        >
                          <Button
                            onClick={() => {
                              deleteEntity();
                              setIsDeactivatedModalOpen(false);
                            }}
                            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                            id="confirmation-dialog-action-btn"
                          >
                            {t('events.deactivate')}
                          </Button>
                        </ConfirmationDialog>

                        <Button
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                          id={'confirmation-dialog-btn'}
                          color="secondary"
                          onClick={() => setIsDeactivatedModalOpen(true)}
                        >
                          {t('events.deactivate')}
                        </Button>
                      </AccessControl>
                    </>
                  )}

                  {id && (
                    <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.VIEW]}>
                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'view-orders-btn'}}
                        id={'view-orders-btn'}
                        color="secondary"
                        onClick={() => navigate(`/${Path.EVENT_ORDERS}/${id}`)}
                      >
                        {t('events.view-orders')}
                      </Button>
                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'view-tickets-btn'}}
                        id={'view-tickets-btn'}
                        color="secondary"
                        onClick={() => navigate(`/${Path.EVENTS}/${id}/tickets`)}
                      >
                        {t('events.view-tickets')}
                      </Button>
                    </AccessControl>
                  )}

                  <Button
                    {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                    id={'confirmation-dialog-btn'}
                    color="secondary"
                    onClick={() => setIsConfirmationDialogOpen(true)}
                  >
                    {t('shared.clear')}
                  </Button>

                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                  >
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                      id={'save-btn'}
                      onClick={() => {
                        formAPI.submit();
                      }}
                    >
                      {id ? t('shared.update') : t('shared.save')}
                    </Button>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12}>
            {isGetEntityLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <FormBuilder
                onRejectSubmit={onRejectSubmit}
                formId={FeatureName.EVENTS}
                api={formAPI}
                initialValues={event}
                onSubmit={onSubmit}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default EventPage;
