export interface ConfigFromBackend {
  [key: string]: any;
}

function collectOverridesFromEnv(): Partial<Config> {
  const overrides = {};
  for (const [key, value] of Object.entries(process.env)) {
    if (key.startsWith('REACT_APP_OVERRIDE_CONFIG_')) {
      const configKey = key.replace('REACT_APP_OVERRIDE_CONFIG_', '');
      overrides[configKey] = value;
      console.warn('Overriding config', configKey, value);
    }
  }
  return overrides;
}

function requiredConfigParam<T>(key: keyof Config, value?: T | null): T {
  if (value !== undefined && value !== null) return value;
  throw Error(`Missing required config key ${key}`);
}

/**
 * These are config keys that should always be returned by backend independently of what configured in DB.
 * If any of these is missing, just fail!
 * The keys are:
 * - ENV_NAME, ORG_NAME and IS_POWER are always added by config-api depending on server environment.
 * - AUTH0 props are dynamically retrieved from Auth0 Management API.
 */

export type ConfigKey = keyof Config;
export const READONLY_CONFIG_KEYS: ConfigKey[] = [
  'ENV_NAME',
  'ORG_NAME',
  'IS_POWER',
  'AUTH0_DOMAIN',
  'AUTH0_ORG_ID',
  'AUTH0_CLIENT_ID',
  'AUTH0_API_AUDIENCE',
  'AUTH0_ORG_NAME',
  'AUTH0_ORG_DISPLAY_NAME',
];

export function buildConfig(config: ConfigFromBackend): Config {
  const overrides = collectOverridesFromEnv();
  return {
    ...config, // this always goes at the beginning!
    ENV_NAME: requiredConfigParam('ENV_NAME', config.ENV_NAME),
    ORG_NAME: requiredConfigParam('ORG_NAME', config.ORG_NAME),
    IS_POWER: requiredConfigParam('IS_POWER', config.IS_POWER), // TODO resolve this from current host
    AUTH0_DOMAIN: requiredConfigParam('AUTH0_DOMAIN', config.AUTH0_DOMAIN),
    AUTH0_ORG_ID: requiredConfigParam('AUTH0_ORG_ID', config.AUTH0_ORG_ID),
    AUTH0_ORG_NAME: requiredConfigParam('AUTH0_ORG_NAME', config.AUTH0_ORG_NAME),
    AUTH0_ORG_DISPLAY_NAME: requiredConfigParam('AUTH0_ORG_DISPLAY_NAME', config.AUTH0_ORG_DISPLAY_NAME),
    AUTH0_CLIENT_ID: requiredConfigParam('AUTH0_CLIENT_ID', config.AUTH0_CLIENT_ID),
    AUTH0_API_AUDIENCE: requiredConfigParam('AUTH0_API_AUDIENCE', config.AUTH0_API_AUDIENCE),
    MUI_LICENSE_KEY: config.MUI_LICENSE_KEY,
    BFF_API_PATH: config.BFF_API_PATH ?? '/api/v1',
    FORM_API_PATH: config.FORM_API_PATH ?? '/api/v1',
    FORM_ADMIN_API_PATH: config.FORM_ADMIN_API_PATH ?? '/api/form/v2/admin',
    FORM_EDITOR_API_PATH: config.FORM_EDITOR_API_PATH ?? '/api/form/v2/admin_v2',
    JOB_API_BASE_URL: config.FORM_EDITOR_API_PATH ?? '/api/job/v1',
    SPANNER_API_PATH: config.FORM_EDITOR_API_PATH ?? '/api/admincrm/v1/spanner',
    TASK_API_PATH: config.TASK_API_PATH ?? '/api/v1',
    PERMISSION_ADMIN_API_PATH: config.PERMISSION_ADMIN_API_PATH ?? '/api/form/v2/admin',
    USER_API_PATH: config.USER_API_PATH ?? '/api/user/v2',
    ORGANIZATION_API_PATH: config.ORGANIZATION_API_PATH ?? '/api/organization/v2',
    AI_CALL_API_PATH: config.AI_CALL_API_PATH ?? '/api',
    ACCOUNT_API_PATH: config.ACCOUNT_API_PATH ?? '/api/account/v1',
    CALLER_ID_API_PATH: config.CALLER_ID_API_PATH ?? '/api/caller-id/v1',
    OUTLET_ORDER_API_PATH: config.OUTLET_ORDER_API_PATH ?? '/api/outlet/v1',
    AI_AGENT_API_PATH: config.AI_VOICES_API_PATH ?? '/api/ai-agent/v1',
    COMMA_SEPARATED_LANGUAGES: config.COMMA_SEPARATED_LANGUAGES ?? 'en',
    SESSION_TIMEOUT_SECONDS: Number(config.SESSION_TIMEOUT_SECONDS ?? 3600),
    FORCE_UPDATE_CAMPAIGN_MIN_COUNT: Number(config.CALL_NOW_BUTTON_DELAY ?? 3),
    FORCE_UPDATE_CAMPAIGN_TIMEOUT_DELAY: Number(config.CALL_NOW_BUTTON_DELAY ?? 60000),
    CALL_NOW_BUTTON_DELAY: Number(config.CALL_NOW_BUTTON_DELAY ?? 5000),
    ...overrides, // this always goes at the end!
  };
}

export interface Config {
  ENV_NAME: string;
  ORG_NAME: string;
  IS_POWER: boolean;
  AUTH0_DOMAIN: string;
  AUTH0_ORG_ID: string;
  AUTH0_ORG_NAME: string;
  AUTH0_ORG_DISPLAY_NAME: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_API_AUDIENCE: string;
  BFF_API_PATH: string;
  FORM_API_PATH: string;
  FORM_ADMIN_API_PATH: string;
  FORM_EDITOR_API_PATH: string;
  JOB_API_BASE_URL: string;
  SPANNER_API_PATH: string;
  TASK_API_PATH: string;
  PERMISSION_ADMIN_API_PATH: string;
  USER_API_PATH: string;
  ORGANIZATION_API_PATH: string;
  AI_CALL_API_PATH: string;
  ACCOUNT_API_PATH: string;
  CALLER_ID_API_PATH: string;
  OUTLET_ORDER_API_PATH: string;
  AI_AGENT_API_PATH: string;
  COMMA_SEPARATED_LANGUAGES: string;
  SESSION_TIMEOUT_SECONDS: number;
  MUI_LICENSE_KEY: string | undefined;
  FORCE_UPDATE_CAMPAIGN_MIN_COUNT: number;
  FORCE_UPDATE_CAMPAIGN_TIMEOUT_DELAY: number;
  CALL_NOW_BUTTON_DELAY: number;
}
