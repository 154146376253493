import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {UserPermissions} from '../../../components/shared/AccessControl';
import {FeatureName, Path} from '../../../../paths';
import {PageStickyHeader} from '../../PageStickyHeader';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../../form/FormBuilder';
import {useFormAPI} from '../../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../../UsePermissions';
import {onRejectSubmit} from '../../../form/errorHandler';
import {OnSubmitHandler} from '../../../form/model';
import {FormState} from '../../../form/state/FormState';
import {TestAttributes} from '../../../TestAttributes';
import {difference} from '../../../form/utils';
import {Skeleton} from '@mui/lab';
import {ResponseListWrapper} from '../../../services/model/ResponseListWrapper';
import {useAxiosContext} from '../../../context/AxiosContext';
import {TicketConfig} from '../../../model/Event';
import useApiRequest from '../../../hooks/useApiRequest';
import TicketConfigPageActions from './TicketConfigPageActions';
import {AxiosPromise} from 'axios';

function TicketConfigPage() {
  const {id} = useParams();
  const {t} = useTranslation();
  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TICKETS_CONFIG);
  const [ticket, setTicket] = useState<TicketConfig | null>(null);
  const formAPI = useFormAPI();

  const {reFetch: getEntityList} = useApiRequest<TicketConfig>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlet-item-configs`, params: {formatted: true}},
    options: {manual: true},
    ignoreLoader: true,
  });

  const {reFetch: deleteEntity} = useApiRequest<TicketConfig>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlet-item-configs/${id}`, method: 'delete'},
    options: {manual: true},
    pageName: 'TicketsConfig',
    redirectBaseUrlAfterSuccess: `/${Path.TICKETS_CONFIG}`,
    fetchEntityList: getEntityList,
  });

  const {
    entityData,
    reFetch: getEntityData,
    isEntityLoading: isGetEntityLoading,
  } = useApiRequest<TicketConfig>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlet-item-configs/${id}`},
    options: {manual: true},
    pageName: 'TicketsConfig',
  });

  const {reFetch: patchEntityData} = useApiRequest<TicketConfig>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlet-item-configs/${id}`, method: 'patch'},
    options: {manual: true},
    pageName: 'TicketsConfig',
    fetchEntityList: getEntityList,
  });

  const {reFetch: postEntityData} = useApiRequest<TicketConfig>({
    useAxios: useAxiosEventAPI,
    config: {url: `/outlet-item-configs`, method: 'post'},
    options: {manual: true},
    pageName: 'TicketsConfig',
    fetchEntityList: getEntityList,
    redirectBaseUrlAfterSuccess: `/${Path.TICKETS_CONFIG}`,
  });

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<TicketConfig>, state: FormState) => {
      if (id) {
        formData = difference(formData, ticket);
        patchEntityData({
          data: formData,
        }).then(() => {
          getEntityData();
        });
      } else {
        postEntityData({
          data: formData,
        });
      }
    },
    [ticket]
  );

  useEffect(() => {
    if (id) {
      getEntityData();
    } else {
      setTicket(null);
    }
  }, [id]);

  useEffect(() => {
    if (entityData) {
      setTicket(entityData);
    }
  }, [entityData]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('tickets-config.headline')}</PageHeadline>
              </Grid>
              <TicketConfigPageActions
                userPermissions={userPermissions}
                handleOnClear={() => setIsConfirmationDialogOpen(true)}
                handleOnDeleteEntity={deleteEntity}
                handleOnSave={() => formAPI.submit()}
                getEntityData={getEntityData}
              />
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12}>
            {isGetEntityLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <FormBuilder
                onRejectSubmit={onRejectSubmit}
                formId={FeatureName.TICKETS_CONFIG}
                api={formAPI}
                initialValues={ticket}
                onSubmit={onSubmit}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default TicketConfigPage;
