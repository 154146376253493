import {Box, Grid} from '@mui/material';
import Container from '@mui/material/Container';
import {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import PageHeadline from '../../components/PageHeadline';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {FeatureName} from '../../../paths';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {FormBuilder} from '../../form/FormBuilder';
import {useFormAPI} from '../../form/FormAPI';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {onRejectSubmit} from '../../form/errorHandler';
import {OnSubmitHandler} from '../../form/model';
import {FormState} from '../../form/state/FormState';
import {TestAttributes} from '../../TestAttributes';
import {difference} from '../../form/utils';
import {Skeleton} from '@mui/lab';
import {useAxiosContext} from '../../context/AxiosContext';
import {TicketConfig, TicketConfigListItem} from '../../model/Event';
import useApiRequest from '../../hooks/useApiRequest';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';

function OrderTicketPage() {
  const {id} = useParams();
  const {t} = useTranslation();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState<boolean>(false);
  const [isDeactivatedModalOpen, setIsDeactivatedModalOpen] = useState<boolean>(false);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TICKETS);
  const [ticket, setTicket] = useState<TicketConfig | null>(null);
  const formAPI = useFormAPI();

  const {useAxiosOutletOrderAPI} = useAxiosContext();

  const {
    entityData,
    isEntityLoading: isGetEntityLoading,
    reFetch: getEntityData,
  } = useApiRequest<TicketConfig>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlet-items/${id}`},
    options: {manual: true},
    pageName: 'OrderTicketPage',
  });

  const {reFetch: getEntityList} = useApiRequest<ResponseListWrapper<TicketConfigListItem>>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlets/${entityData?.outlet_id}/outlet-items`, params: {formatted: true}},
    pageName: 'TicketListPage',
    options: {manual: true},
  });

  const {reFetch: patchEntityData} = useApiRequest<TicketConfig>({
    useAxios: useAxiosOutletOrderAPI,
    config: {url: `/outlet-items/${id}`, method: 'PATCH', params: {formatted: true}},
    options: {manual: true},
    pageName: 'OrderTicketPage',
    fetchEntityList: getEntityList,
  });

  const onSubmit: OnSubmitHandler = useCallback(
    (formData: Partial<TicketConfig>, state: FormState) => {
      if (id) {
        formData = difference(formData, ticket);
        patchEntityData({
          data: formData,
        }).then(() => {
          getEntityData();
        });
      }
    },
    [ticket]
  );

  useEffect(() => {
    if (id) {
      getEntityData();
    } else {
      setTicket(null);
    }
  }, [id]);

  useEffect(() => {
    if (entityData) {
      setTicket(entityData);
    }
  }, [entityData]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('order-tickets.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  {id && (
                    <AccessControl
                      userPermissions={userPermissions}
                      allowedPermissions={[UserPermissions.DEACTIVATE_FEATURE_ITEM]}
                    >
                      <ConfirmationDialog
                        message={t('order-tickets.deactivate-message')}
                        headline={t('order-tickets.deactivate')}
                        isDialogOpen={isDeactivatedModalOpen}
                        handleClose={() => setIsDeactivatedModalOpen(false)}
                      >
                        <Button
                          onClick={() => {
                            setIsDeactivatedModalOpen(false);
                          }}
                          {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
                          id="confirmation-dialog-action-btn"
                        >
                          {t('order-tickets.deactivate')}
                        </Button>
                      </ConfirmationDialog>

                      <Button
                        {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                        id={'confirmation-dialog-btn'}
                        color="secondary"
                        onClick={() => setIsDeactivatedModalOpen(true)}
                      >
                        {t('order-tickets.deactivate')}
                      </Button>
                    </AccessControl>
                  )}

                  <Button
                    {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-btn'}}
                    id={'confirmation-dialog-btn'}
                    color="secondary"
                    onClick={() => setIsConfirmationDialogOpen(true)}
                  >
                    {t('shared.clear')}
                  </Button>

                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[id ? UserPermissions.MODIFY : UserPermissions.CREATE]}
                  >
                    <Button
                      {...{[TestAttributes.BUTTON_NAME]: 'save-btn'}}
                      id={'save-btn'}
                      onClick={() => {
                        formAPI.submit();
                      }}
                    >
                      {id ? t('shared.update') : t('shared.save')}
                    </Button>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>
          <Grid item xs={12}>
            {isGetEntityLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <FormBuilder
                onRejectSubmit={onRejectSubmit}
                formId={FeatureName.TICKETS}
                api={formAPI}
                initialValues={ticket}
                onSubmit={onSubmit}
              />
            )}
          </Grid>
        </Grid>
        <ConfirmationDialog
          message={t('shared.clear-form-modal-content')}
          headline={t('shared.clear-form-modal-headline')}
          isDialogOpen={isConfirmationDialogOpen}
          handleClose={() => setIsConfirmationDialogOpen(false)}
        >
          <Button
            onClick={() => {
              formAPI.reset();
              setIsConfirmationDialogOpen(false);
            }}
            {...{[TestAttributes.BUTTON_NAME]: 'confirmation-dialog-action-btn'}}
            id="confirmation-dialog-action-btn"
          >
            {t('shared.accept')}
          </Button>
        </ConfirmationDialog>
      </Container>
    </Box>
  );
}

export default OrderTicketPage;
