import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAuth0} from '@auth0/auth0-react';
import {PageTopActions} from '../PageTopActions';
import {useTranslation} from 'react-i18next';
import {Box, Button, Container, FormControlLabel, Grid, Switch} from '@mui/material';
import {Skeleton} from '@mui/lab';
import PageHeadline from '../../components/PageHeadline';
import {TaskListItem} from '../../model/Task';
import AccessControl, {UserPermissions} from '../../components/shared/AccessControl';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {FeatureName} from '../../../paths';
import {Auth0User, getUserId} from '../../model/Auth0User';
import {PageStickyHeader} from '../PageStickyHeader';
import {useTableColumns} from '../UseTableColumns';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import DataGridCustom from '../../components/DataGridCustom';
import {TestAttributes} from '../../TestAttributes';
import SpartanSnackbar from '../../components/SpartanSnackbar';
import {NotificationType} from '../../services/NotificationService';
import {useAxiosContext} from '../../context/AxiosContext';
import {useLoading} from '../../context/LoadingContext';

function TaskListPage() {
  const {t} = useTranslation();
  const {useAxiosTaskAPI} = useAxiosContext();
  const {setLoading} = useLoading();
  const auth0 = useAuth0<Auth0User>();
  const [filteredTasks, setFilteredTasks] = useState<Array<TaskListItem>>([]);
  const [showAll, setShowAll] = useState<boolean>(true);
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.TASKS);

  const tableColumns = useTableColumns({
    featureName: FeatureName.TASKS,
    defaultActionColumn: true,
  });

  const [{data: entityList, loading: isEntityListLoading, error: hasEntityListError}] = useAxiosTaskAPI<
    ResponseListWrapper<TaskListItem>
  >(
    {url: `/${FeatureName.TASKS}`, params: {formatted: true}},
    {
      manual: false,
    }
  );

  function getMyTasks(tasks: Array<TaskListItem>): Array<TaskListItem> {
    return tasks?.filter((task: TaskListItem) => task?.assignee?.value === getUserId(auth0.user));
  }

  useEffect(() => {
    if (!showAll) {
      const myTasks = getMyTasks(entityList?.results || []);
      setFilteredTasks(myTasks);
    } else {
      setFilteredTasks(entityList?.results || []);
    }
  }, [entityList, showAll]);
  useEffect(() => {
    setLoading(isEntityListLoading, 'TaskPageList');
  }, [isEntityListLoading]);
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {hasEntityListError && (
            <SpartanSnackbar type={NotificationType.ERROR} message={hasEntityListError.message}></SpartanSnackbar>
          )}
          <PageStickyHeader>
            <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
              <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
                <PageHeadline>{t('tasks.headline')}</PageHeadline>
              </Grid>
              <Grid item xs={12} md={6}>
                <PageTopActions>
                  <AccessControl
                    userPermissions={userPermissions}
                    allowedPermissions={[UserPermissions.VIEW_ALL_TASKS]}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          {...{[TestAttributes.FIELD_NAME]: 'selected-mode'}}
                          color={'primary'}
                          checked={showAll}
                          onChange={() => setShowAll(!showAll)}
                          id={'switch-btn '}
                        />
                      }
                      label={`${t('tasks.view-all')}`}
                    />
                  </AccessControl>

                  <AccessControl userPermissions={userPermissions} allowedPermissions={[UserPermissions.CREATE]}>
                    <Link to="/tasks/create">
                      <Button fullWidth color="secondary" id={'add-btn'} {...{[TestAttributes.BUTTON_NAME]: 'add-btn'}}>
                        {t('globalActions.addButton')}
                      </Button>
                    </Link>
                  </AccessControl>
                </PageTopActions>
              </Grid>
            </Grid>
          </PageStickyHeader>

          <Grid item xs={12} sx={{height: '750px'}} {...{[TestAttributes.TABLE_NAME]: 'table-results'}}>
            {isEntityListLoading ? (
              <Skeleton variant="rectangular" height={500} />
            ) : (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={
                  filteredTasks?.map((item: TaskListItem, index: number) => {
                    return {
                      ...item,
                      status: item.status.label,
                      type: item.type.label,
                      assignee: item.assignee.label,
                      actions: item.task_id,
                      id: index,
                    };
                  }) || []
                }
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default TaskListPage;
