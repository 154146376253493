import {useEffect} from 'react';
import NotificationService, {NotificationType} from '../services/NotificationService';
import {Options, UseAxios} from 'axios-hooks';
import {useLoading} from '../context/LoadingContext';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {useNavigate} from 'react-router-dom';
import {NavigateFunction} from 'react-router';

interface useFetchTicketConfigProps {
  useAxios: UseAxios;
  config: AxiosRequestConfig;
  options: Options;
  pageName?: string;
  fetchEntityList?: Function;
  redirectBaseUrlAfterSuccess?: string;
  ignoreLoader?: boolean;
}

function getNotificationSuccessMessage<T>(entityResponse: AxiosResponse<T>, config: AxiosRequestConfig) {
  let notifMessage = entityResponse.data?.['message'];
  if (!notifMessage && config.method?.toLowerCase() === 'patch') {
    notifMessage = 'Succesfully Updated';
  } else if (!notifMessage && config.method?.toLowerCase() === 'post') {
    notifMessage = 'Succesfully Created';
  } else if (!notifMessage && config.method?.toLowerCase() === 'delete') {
    notifMessage = 'Succesfully Deleted';
  }
  return notifMessage;
}

function shouldNotifyAndFetch<T>(
  config: AxiosRequestConfig,
  entityResponse?: AxiosResponse<T>,
  fetchEntityList?: Function
): boolean {
  return (
    entityResponse !== undefined &&
    ['post', 'patch', 'delete'].includes(config.method?.toLowerCase() || '') &&
    [200, 201].includes(entityResponse.status) &&
    fetchEntityList !== undefined
  );
}

function buildRedirectUrl<T>(
  redirectBaseUrlAfterSuccess: string,
  config: AxiosRequestConfig,
  entityResponse: AxiosResponse<T>
) {
  let redirectUrl = redirectBaseUrlAfterSuccess;
  if (config.method?.toLowerCase() === 'post') {
    redirectUrl += `/${entityResponse.data['id']}`;
  }
  return redirectUrl;
}

function redirectIfNeeded<T>(
  redirectBaseUrlAfterSuccess: string | undefined,
  config: AxiosRequestConfig,
  entityResponse: AxiosResponse<T>,
  navigate: NavigateFunction
) {
  if (redirectBaseUrlAfterSuccess) {
    let redirectUrl = buildRedirectUrl(redirectBaseUrlAfterSuccess, config, entityResponse);
    navigate(redirectUrl);
  }
}

export default function useApiRequest<T>({
  useAxios,
  config,
  options,
  pageName,
  fetchEntityList,
  redirectBaseUrlAfterSuccess,
  ignoreLoader,
}: useFetchTicketConfigProps) {
  const {setLoading} = useLoading();
  const navigate = useNavigate();
  const [{data: entityData, loading: isEntityLoading, error: entityError, response: entityResponse}, reFetch] =
    useAxios<T>(config, options);

  useEffect(() => {
    if (!ignoreLoader && pageName) {
      setLoading(isEntityLoading, pageName);
    }
  }, [isEntityLoading]);

  useEffect(() => {
    if (entityError) {
      NotificationService.getInstance().sendNotification(
        entityError?.response?.data?.message || 'Error',
        NotificationType.ERROR
      );
    }
  }, [entityError]);

  useEffect(() => {
    if (shouldNotifyAndFetch(config, entityResponse, fetchEntityList)) {
      let notificationMessage = getNotificationSuccessMessage(entityResponse!, config);
      NotificationService.getInstance().sendNotification(notificationMessage, NotificationType.SUCCESS);

      fetchEntityList!().then(() => {
        redirectIfNeeded(redirectBaseUrlAfterSuccess, config, entityResponse!, navigate);
      });
    }
  }, [entityResponse]);
  return {entityData, isEntityLoading, entityError, reFetch};
}
